.container{
    display: flex;
    width: 100%;
    height: 100%;
    position:absolute
}
.ironImage{
    width: 30%;
    height: 100%;}
.formHolder{
    width: 100%;
    display: flex;
justify-content: center;
background-color:#4a4a48
}
.form{
position:relative;
display: block;
margin: auto;
width: 60%;
border-radius: 2rem;
padding: 2rem 2rem 0 2rem;
background-color:white
}
.fields{

   text-align: center;
}
.input{
    border: none;
    border-radius: 1rem;
    height: 2rem;
    background-color: #ebebe3;
    padding: 0.5rem;
    width: 70%;
    text-align: center;
    font-size: 1rem;
}
.calculateBtn{
    /* display:block;
    position: absolute; */
    bottom:   10%;
    /* left:26%; */
    font-size: 1.3rem;
    border-radius: 1rem;
    border: none;
    background-color:#ea9215;
    padding: 1rem;
    /* width: 30%; */
    cursor: pointer;
    margin-bottom: 1rem;
}
.btn {
    border: crimson solid 0.1rem;
    text-align: center;
    height: 50px;
    border-radius: 12px;
    background-color: white;
  }
  .selectFile{
    font-size: 1.5rem;
    font-family: 'Times New Roman', Times, serif;
    color: #ea9215;
    padding-left: 0.5rem;
    text-align: center;
  }
